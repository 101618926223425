import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Navbartop from "./navbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPartyCook } from "../store/slices/cook";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { BsGlobe2 } from "react-icons/bs";
import Moment from "moment";
import { API_PATHS } from "../utils/constants/api.constants";
import { getCuisines } from "../store/slices/config";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { MdBlock } from "react-icons/md";
import ExportCSVButton from "../utils/helpers/ExportButtons";
import ShowSkeleton from "../utils/helpers/Skeleton";
import dog from "../Assets/Images/corgi.png";

const PartyCook = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totaldata, settotalData] = useState();
  const [cuisines, setCuisines] = useState([]);
  const [chefCuisines, setchefCuisines] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [location, setLocation] = useState("");
  const [errors, setErrors] = useState({});
  const [exportData, setExportData] = useState([]);
  const [nameEmailOrMobile, setnameEmailOrMobile] = useState("");
  const dispatch = useDispatch();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [isLoading, setisLoading] = useState(true);
  const [limit, setLimit] = useState();
  const [cuisineInputval, setCuisienInputVal] = useState("");
  const [cuisineError, setCuisineError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [item, setItem] = useState({
    limit: 25,
    page: 1,
  });

  // console.log(data);

  // react paginate
  const itemsPerPage = 25;
  const pagesToShowInitially = 3;

  const handleApplyFilter = () => {
    const item = {
      cuisines: chefCuisines.length ? chefCuisines : undefined,
      latitude: coordinates[0] || undefined,
      longitude: coordinates[1] || undefined,
      nameEmailOrMobile: nameEmailOrMobile || undefined,
      limit: 25,
      page: 1,
    };
    if (cuisineInputval && !chefCuisines.length) {
      setCuisineError("Please Select valid cuisine");
    } else {
      setItem(item);
      setCurrentPage(1);
      search.set("page", 1);
      setSearch(search, {
        replace: true,
      });
      getpartycook(item);
    }
  };

  useEffect(() => {
    if (location === "") {
      setCoordinates([]);
    }
  }, [location]);

  const handlePageClick = async ({ selected }) => {
    const newPage = selected + 1;

    if (newPage === currentPage) {
      return;
    }
    const updatedItem = {
      ...item,
      limit: 25,
      page: newPage,
    };
    setItem(updatedItem);
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
    await getpartycook(updatedItem);
  };

  useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  }, []);

  var options = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  useEffect(() => {
    document.title = "CookandChef Admin | Party Cook";
  }, []);

  const handlenameChange = (e) => {
    setnameEmailOrMobile(e.target.value);
    search.set("nameEmailOrMobile", e.target.value);
    setSearch(search, {
      replace: true,
    });
  };

  const inputChangeCuisine = (e) => {
    setCuisienInputVal(e);
    setCuisineError("");
  };

  const handleChefCuisineChange = (selected) => {
    setCuisineError("");
    setCuisienInputVal("");

    const validationErrors = { ...errors };
    setchefCuisines(selected);
    search.set("cuisines", selected.join(","));
    setSearch(search, {
      replace: true,
    });
    validationErrors.chefCuisines =
      selected.length === 0 ? "Please enter cuisines known" : "";
    if (!selected.length === 0) {
      delete validationErrors.chefCuisines;
    }

    setErrors(validationErrors);
  };
  const inputRef = useRef(null);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const googleMapsScript = document.createElement("script");
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${API_PATHS.googlemapsapikey}&libraries=places`;
      googleMapsScript.onload = initializeAutocomplete;
      googleMapsScript.onerror = () => {
        console.error("Failed to load Google Maps API script");
      };
      document.body.appendChild(googleMapsScript);
    };

    const initializeAutocomplete = () => {
    if (inputRef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();
        if (!place.geometry || !place.geometry.location) {
          setLocationError(
            "Please select location from the suggestion drop-down"
          );
        } else {
          setLocationError("");
          setLocation(place.name);
          const coordinates = [
            place.geometry.location.lat(),
            place.geometry.location.lng(),
          ];
          setCoordinates(coordinates);
          localStorage.setItem("coordinates", coordinates);
         
        }
      });
      inputRef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();
        const inputValue = inputRef.current.value.trim();
        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setLocationError(
            "Please select location from the suggestion drop-down"
          );
        }
      });
    }
  };
  loadGoogleMapsScript();


  return () => {
    // Clean up code here, if needed
  };
}, []); 

useEffect(()=>{
  if(location !== ""){
  search.set("location", location);
  setSearch(search, {
    replace: true,
  })
}
},[location])

  const handlelocationChange = (e) => {
    setLocation(e.target.value);
    setLocationError("");
  };

  useEffect(() => {
    const locationq = search.get("location");
    const keywordq = search.get("nameEmailOrMobile");
    const pageq = search.get("page");
    const cuisineval = search.get("cuisines")?.split(",") || [];

    if (cuisineval !== null && cuisineval[0] !== "") {
      setchefCuisines(cuisineval);
    }

    if (keywordq) {
      setnameEmailOrMobile(keywordq);
    }

    let coordArray;

    if (locationq !== null) {
      setLocation(locationq);
      const coordString = localStorage.getItem("coordinates");
      coordArray = coordString ? coordString.split(",") : [];
      setCoordinates(coordArray);
    }

    const item = {
      cuisines: cuisineval.length ? cuisineval : undefined,
      latitude: coordArray ? coordArray[0] : undefined,
      longitude: coordArray ? coordArray[1] : undefined,
      nameEmailOrMobile: keywordq || undefined,
      limit: 25,
      page: parseInt(pageq) || 1,
    };

    getpartycook(item);
  }, []);

  const getpartycook = (item) => {
    dispatch(getPartyCook(item))
      .unwrap()
      .then((data) => {
        setData(data.users.data);
        settotalData(data.users.totalDataCount);

        setLimit(data.users.totalDataCount);
        setisLoading(true);
        setTimeout(() => {
          setisLoading(false);
        }, [1000]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const handleResetFilter = (e) => {
    e.preventDefault();
    setchefCuisines([]);
    setLocation("");
    setCoordinates([]);
    setnameEmailOrMobile("");
    const item = {
      limit: 25,
      page: 1,
    };
    setItem(item);
    setSearch([]);
    setCurrentPage(1);
    getpartycook(item);
  };

  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;
  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; Party Cook
                  </div>
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Party Cook</h4>
                      </div>

                      {role === "admin" && (
                        <div align="right">
                          <div>
                            <ExportCSVButton
                              limit={limit}
                              filename="PartyCook_data.xlsx"
                              getData={getPartyCook}
                              item={item}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="cards ps-1 pe-1">
                      <Form>
                        <Row className="d-flex flex-row card ps-2 pe-2">
                          <Col
                            lg={3}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-2"
                          >
                            <Form.Group>
                              <Typeahead
                                clearButton
                                id="basic-typeahead-multiple"
                                labelKey="name"
                                multiple
                                className="input1"
                                onChange={handleChefCuisineChange}
                                options={options}
                                placeholder="Cuisines"
                                selected={chefCuisines}
                                isInvalid={!!cuisineError}
                                onInputChange={inputChangeCuisine}
                              />
                              <Form.Control.Feedback type="invalid">
                                {cuisineError}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col
                            lg={3}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-2"
                          >
                            <Form.Group>
                              <Form.Control
                                name="location"
                                placeholder="Enter location"
                                value={location}
                                ref={inputRef}
                                onChange={handlelocationChange}
                                isInvalid={!!locationError}
                              />
                              <Form.Control.Feedback type="invalid">
                                {locationError}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col
                            lg={3}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-2"
                          >
                            <Form.Group>
                              <Form.Control
                                name="nameEmailOrMobile"
                                placeholder="Name/ Email/ Mobile"
                                value={nameEmailOrMobile}
                                onChange={handlenameChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <div className="group d-flex justify-content-center mt-1 ">
                          <Button
                            className="cancel_btn"
                            onClick={handleResetFilter}
                          >
                            Reset
                          </Button>{" "}
                          <Button
                            className="apply_btn ms-2"
                            onClick={handleApplyFilter}
                          >
                            Search
                          </Button>{" "}
                        </div>
                      </Form>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {isLoading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : data?.length === 0 ? (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              style={{
                                backgroundColor: "white",
                                // height: "20dvh",
                                // width: "100%",
                                borderRadius: "15px",
                                padding: "20px",
                              }}
                            >
                              <Col lg={12} align="center">
                                <p style={{ fontSize: "18px" }}>
                                  <b>No results</b> found for your search!
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Table responsive hover>
                            <thead>
                              <tr>
                                <th className="text-left">Name</th>
                                <th className="text-left">Mobile</th>
                                <th className="text-left">Email</th>
                                <th className="text-center">Date of Regd</th>
                                <th className="text-center"> Last Login</th>
                                <th className="text-center">
                                  My Profile/
                                  <br /> Party Profile
                                </th>
                                <th className="text-left">Web/ App</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((cook) => (
                                <tr key={cook._id}>
                                  <td className="text-left">
                                    {" "}
                                    <Link
                                      to={`/partycookdetails/${cook._id}`}
                                      style={{
                                        color: "#a020f0",
                                      }}
                                    >
                                      {cook?.status === 3 && (
                                        <MdBlock
                                          className="me-2"
                                          style={{ color: "red" }}
                                        />
                                      )}
                                      {cook?.fullName?.substring(0, 25)}
                                    </Link>
                                  </td>
                                  <td className="text-left">{cook?.mobile}</td>

                                  <td className="text-left">
                                    {" "}
                                    {cook?.email?.substring(0, 25)}
                                  </td>

                                  <td className="text-center">
                                    {Moment.utc(cook?.createdAt).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {Moment.utc(cook?.lastLoginDateTime).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>

                                  <td className="text-center">
                                    {cook?.basicProfileStatus === 0 && (
                                      <span
                                        style={{
                                          color: "#f06548",

                                          fontWeight: "500",
                                        }}
                                      >
                                        No
                                      </span>
                                    )}
                                    {cook?.basicProfileStatus === 1 && (
                                      <span
                                        style={{
                                          color: "#099885",

                                          fontWeight: "500",
                                        }}
                                      >
                                        Yes
                                      </span>
                                    )}
                                    /&nbsp;
                                    {cook?.partyCookProfileStatus === 0 && (
                                      <span
                                        style={{
                                          color: "#f06548",

                                          fontWeight: "500",
                                        }}
                                      >
                                        No
                                      </span>
                                    )}
                                    {cook?.partyCookProfileStatus === 1 && (
                                      <span
                                        style={{
                                          color: "#099885",

                                          fontWeight: "500",
                                        }}
                                      >
                                        Yes
                                      </span>
                                    )}
                                  </td>

                                  <td className="text-left">
                                    {cook?.webAccess === 0 && (
                                      <BsGlobe2
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {cook?.webAccess === 1 && (
                                      <BsGlobe2
                                        style={{
                                          color: "orange",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {!(
                                      cook?.webAccess === 0 ||
                                      cook?.webAccess === 1
                                    ) && (
                                      <BsGlobe2
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {!(
                                      cook?.appAccess === 0 ||
                                      cook?.appAccess === 1
                                    ) && (
                                      <HiOutlineDevicePhoneMobile
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {cook?.appAccess === 0 && (
                                      <HiOutlineDevicePhoneMobile
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {cook?.appAccess === 1 && (
                                      <HiOutlineDevicePhoneMobile
                                        style={{
                                          color: "blue",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                      <div className="justify-content-right">
                        {totaldata / itemsPerPage > 1 && (
                          <ReactPaginate
                            key={currentPage}
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            breakLinkClassName={"page-link"}
                            pageCount={Math.ceil(totaldata / itemsPerPage)}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={pagesToShowInitially}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            disabledClassName="disabled"
                            initialPage={currentPage - 1}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartyCook;
