import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Navbartop from "./navbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import Moment from "moment";
import { Link } from "react-router-dom";
import { getJobs, SendNotifications, SendStatus } from "../store/slices/jobs";
import ShowSkeleton from "../utils/helpers/Skeleton";

import { getCuisines } from "../store/slices/config";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { IoIosMailUnread } from "react-icons/io";

function JobsList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [totalData, settotalData] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [item, setItem] = useState({
    limit: 25,
    page: 1,
  });
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [cuisines, setCuisines] = useState([]);
  const [chefCuisines, setchefCuisines] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [nameEmailOrMobile, setnameEmailOrMobile] = useState("");
  const [errors, setErrors] = useState({});
  const [location, setLocation] = useState("");
  const [minimumSalary, setminimumSalary] = useState("5000");
  const [maximumSalary, setmaximumSalary] = useState("25000");
  const [minVal, setMinVal] = useState(5000);
  const [maxVal, setMaxVal] = useState(100000);
  const [min, setMin] = useState(5000);
  const [max, setMax] = useState(100000);
  const [cuisineInputval, setCuisienInputVal] = useState("");
  const [cuisineError, setCuisineError] = useState("");
  const [locationError, setLocationError] = useState("");
  // react paginate
  const totalPages = 10;
  const itemsPerPage = 25;
  const pagesToShowInitially = 2;

  const handlePageClick = async ({ selected }) => {
    const newPage = selected + 1;

    if (newPage === currentPage) {
      return;
    }
    const updatedItem = {
      ...item,
      limit: 25,
      page: newPage,
    };
    setItem(updatedItem);
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
    await getjobs(updatedItem);
  };

  useEffect(() => {
    if (location === "") {
      setCoordinates([]);
    }
  }, [location]);
  useEffect(() => {
    document.title = "CookandChef Admin | Job List";
  }, []);

  const inputChangeCuisine = (e) => {
    setCuisienInputVal(e);
    setCuisineError("");
  };
  const handleChefCuisineChange = (selected) => {
    setCuisineError("");
    setCuisienInputVal("");

    const validationErrors = { ...errors };
    search.set("cuisines", selected.join(","));
    setSearch(search, {
      replace: true,
    });
    setchefCuisines(selected);
    validationErrors.chefCuisines =
      selected.length === 0 ? "Please enter cuisines" : "";
    if (!selected.length === 0) {
      delete validationErrors.chefCuisines;
    }

    setErrors(validationErrors);
  };

  const inputRef = useRef(null);
  //useeffect
  useEffect(() => {
    if (inputRef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();
        if (!place.geometry || !place.geometry.location) {
          setLocationError(
            "Please select location from the suggestion drop-down"
          );
        } else {
          setLocationError("");
          setLocation(place.name);
          const coordinates = [
            place.geometry.location.lat(),
            place.geometry.location.lng(),
          ];
          setCoordinates(coordinates);
          localStorage.setItem("coordinates", coordinates);
          setCoordinates(coordinates);
          
        }
      });
      inputRef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();
        const inputValue = inputRef.current.value.trim();
        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setLocationError(
            "Please select location from the suggestion drop-down"
          );
        }
      });
    }
  }, []);
  
  useEffect(()=>{
    if(location !== ""){
    search.set("location", location);
    setSearch(search, {
      replace: true,
    })
  }
  },[location])

  var options = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const handlelocationChange = (e) => {
    setLocation(e.target.value);
    setLocationError("");
  };

  const HandleChange = ({ max, min }) => {
    setminimumSalary(min);
    setmaximumSalary(max);
  };

  const handleApplyFilter = () => {
    search.set("minimumSalary", minimumSalary);
    search.set("maximumSalary", maximumSalary);

    search.set("page", 1);
    setSearch(search, {
      replace: true,
    });
    const item = {
      cuisines:
        chefCuisines[0] !== "" && chefCuisines.length
          ? chefCuisines
          : undefined,
      minimumSalary: parseInt(minimumSalary) || undefined,
      maximumSalary: parseInt(maximumSalary) || undefined,
      location: location || undefined,
      nameEmailOrMobile: nameEmailOrMobile || undefined,
      limit: 25,
      page: 1,
    };
    if (cuisineInputval && !chefCuisines.length) {
      setCuisineError("Please Select valid cuisine");
    } else if (locationError) {
    } else {
      setItem(item);
      setCurrentPage(1);
      getjobs(item);
    }
  };

  const handlenameChange = (e) => {
    setnameEmailOrMobile(e.target.value);
    search.set("nameEmailOrMobile", e.target.value);
    setSearch(search, {
      replace: true,
    });
  };

  const handleResetFilter = (e) => {
    e.preventDefault();
    setchefCuisines([]);
    setMinVal(5000);
    setMaxVal(100000);
    setminimumSalary(5000);
    setmaximumSalary(100000);
    setLocation("");
    setnameEmailOrMobile("");
    setCoordinates([]);
    setSearch([]);
    localStorage.removeItem("coordinates");
    const item = {
      limit: 25,
      page: 1,
    };
    setItem(item);
    setSearch([]);
    setCurrentPage(1);
    getjobs(item);
  };

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  }, [dispatch]);

  useEffect(() => {
    const cuisineval = search.get("cuisines")?.split(",") ?? [];
    const minsal = search.get("minimumSalary");
    const maxsal = search.get("maximumSalary");
    const keywordq = search.get("nameEmailOrMobile");
    const locationq = search.get("location");
    const pageq = search.get("page");

    if (cuisineval !== null && cuisineval[0] !== "") {
      setchefCuisines(cuisineval);
    }
    if (keywordq) {
      setnameEmailOrMobile(keywordq);
    }

    if (minsal !== null) {
      setMinVal(parseInt(minsal));
      setminimumSalary(parseInt(minsal));
    }
    if (maxsal !== null) {
      setMaxVal(parseInt(maxsal));
      setmaximumSalary(parseInt(maxsal));
    }

    let coordString;
    let coordArray;
    if (locationq !== null) {
      setLocation(locationq);
    }

    if (cuisineval || minsal || locationq || keywordq || pageq) {
      const item = {
        cuisines:
          cuisineval[0] !== "" && cuisineval.length ? cuisineval : undefined,
        minimumSalary: parseInt(minsal) || undefined,
        maximumSalary: parseInt(maxsal) || undefined,
        nameEmailOrMobile: keywordq || undefined,
        location: locationq || undefined,
        limit: 25,
        page: parseInt(pageq) || 1,
      };

      setItem(item);
      getjobs(item);
    }
  }, []);

  const getjobs = (item) => {
    dispatch(getJobs(item))
      .unwrap()
      .then((data) => {
        setData(data.jobs.data);
        settotalData(data.jobs.totalDataCount);
        setisLoading(true);
        setTimeout(() => {
          setisLoading(false);
        }, [1000]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const sendNotification = (id) => {
    dispatch(SendStatus(id))
      .unwrap()
      .then((data) => {
        getjobs();
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  console.log(data);

  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; Jobs
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Jobs</h4>
                      </div>
                    </div>

                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {isLoading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : data?.length === 0 ? (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              style={{
                                backgroundColor: "white",
                                // height: "20dvh",
                                // width: "100%",
                                borderRadius: "15px",
                                padding: "20px",
                              }}
                            >
                              <Col lg={12} align="center">
                                <p style={{ fontSize: "18px" }}>
                                  <b>No Data</b> found!
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Table responsive hover>
                            <thead>
                              <tr>
                                <th className="text-left">Designation</th>
                                <th className="text-left">Employer Name</th>
                                <th className="text-left">Salary</th>
                                <th className="text-left">Location</th>
                                <th className="text-center">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((job) => (
                                <tr key={job?.id}>
                                  <td className="text-left">
                                    <Link
                                      to={`/JobsDetails/${job?._id}`}
                                      style={{
                                        color: "#a020f0",
                                      }}
                                    >
                                      {job?.designation?.substring(0, 20)}
                                    </Link>
                                  </td>
                                  <td className="text-left">
                                    <Link
                                      to={`/employerdetails/${job?.employerData?._id}`}
                                      style={{
                                        color: "#a020f0",
                                      }}
                                    >
                                      {job?.employerData?.fullName?.substring(
                                        0,
                                        25
                                      )}
                                    </Link>
                                  </td>

                                  <td className="text-left">
                                    Rs. {job?.salary}
                                  </td>
                                  <td className="text-left">
                                    {job?.location?.substring(0, 15)}
                                  </td>

                                  {role === "admin" && (
                                    <td className="text-center">
                                      {job?.isAdminViewed === 0 && (
                                        <div>
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              sendNotification(job?._id)
                                            }
                                          >
                                            <IoIosMailUnread
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                            />
                                          </span>
                                        </div>
                                      )}
                                    </td>
                                  )}
                                  {role !== "admin" && (
                                    <td className="text-center">
                                      {job?.isCSViewed === 0 && (
                                        <div>
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              sendNotification(job?._id)
                                            }
                                          >
                                            <IoIosMailUnread
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                            />
                                          </span>
                                        </div>
                                      )}
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                      <div className="justify-content-center">
                        {totalData / itemsPerPage > 1 && (
                          <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            breakLinkClassName={"page-link"}
                            pageCount={totalData / itemsPerPage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={pagesToShowInitially}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            disabledClassName="disabled"
                            initialPage={currentPage - 1}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobsList;
