import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Moment from "moment";
import { IoMdEye } from "react-icons/io";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import empimg from "../../src/Assets/Images/restaurant.png";
import ShowprofileSkeleton from "./ProfileSkeleton";
import DataSkeleton from "./dataskeleton";
import { SendNotifications, getJobsDetails } from "../store/slices/jobs";
import { getSharedProfiles } from "../store/slices/events";

function JobsDetails() { 
  const { id } = useParams();
  localStorage.setItem("jobId", id);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const navigate = useNavigate();
  const [sharedCount, setSharedCount] = useState();
  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;
  const renderViews = (count) => {
    if (count < 1) return null;
    return `${count} view${count > 1 ? 's' : ''}`;
  };
  React.useEffect(() => {
    dispatch(getJobsDetails(id))     
      .unwrap()
      .then((data) => {
        console.log(data)
        setData(data.jobs.data[0]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    const item = {
      id: id,
    };
    dispatch(getSharedProfiles(item))
      .unwrap()
      .then((data) => {
        setSharedCount(data.profiles.dataCount);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  useEffect(() => {
    document.title = "CookandChef Admin| Job Details";
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
  }, []);

  const sendNotification = () => {
    dispatch(SendNotifications(id))
      .unwrap()
      .then((data) => {
        alert(data?.jobs?.message);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const ShareJob = () => {
    navigate(`/share-chef-profile/${id}`);
    localStorage.setItem("Employerid", data?.employerId?.id);
  };

  return (
    <>
      <div>
        <Navbartop />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12">
                    <div
                      className="text-start my-2 ms-1"
                      style={{
                        fontSize: "2.2dvh",
                        fontWeight: "600",
                        color: "#ff5c09",
                      }}
                    >
                      <Link to="/home">Home</Link> &#8811;{" "}
                      <Link to="/jobs">Jobs</Link> &#8811; Jobs Details
                    </div>

                    <div className="card">
                      <div className="card-header">
                        {isLoading ? (
                          <Row>{ShowprofileSkeleton()}</Row>
                        ) : (
                          <Row>
                            <Col xs={4} md={2} lg={2} xl={2}>
                              {data?.employerId?.dp ? (
                                <img
                                  src={data?.employerId?.dp}
                                  alt=""
                                  style={{
                                    height: "70px",
                                    width: "70px",
                                    borderRadius: "20%",
                                  }}
                                  className="mt-2"
                                />
                              ) : (
                                <img
                                  src={empimg}
                                  alt=""
                                  style={{
                                    height: "70px",
                                    width: "70px",
                                    borderRadius: "20%",
                                  }}
                                  className="mt-2"
                                />
                              )}
                            </Col>
                            <Col
                              xs={6}
                              md={4}
                              lg={4}
                              xl={4}
                              align="left"
                              className="ps-2"
                            >
                              <h5>{data?.designation}</h5>
                              <p>
                                {data?.contactNumber}
                                <br />
                                Posted on:&nbsp;
                                {Moment.utc(data?.createdAt).format(
                                  "DD-MM-YYYY"
                                )}
                              </p>
                              <p>
                                {" "}
                                {data?.location}, {data?.pincode}
                              </p>
                              {data?.currentResponsesCount !== 0 && (
                                <Link
                                  to={`/jobs/applications/${data?.id}`}
                                  style={{ color: "#a020f0" }}
                                >
                                  {data?.currentResponsesCount} Applications
                                </Link>
                              )}
                            </Col>
                            <Col
                              className="d-none d-md-block d-lg-block"
                              md={4}
                              lg={4}
                              xl={4}
                              align="left"
                            >
                              <Link
                                to={`/employerdetails/${data?.employerId?._id}`}
                              >
                                <h5
                                  style={{
                                    color: "#a020f0",
                                  }}
                                >
                                  {data?.employerId?.fullName}
                                </h5>
                              </Link>

                              <p>
                                Email:
                                {data?.employerId?.email}
                                <br />
                                Mobile Number:
                                {data?.employerId?.mobile}
                            
                              </p>
                              

                              {role !== "Associate" && (
                                <Link
                                  style={{ color: "#a020f0" }}
                                  to={`/shared-profiles-list/${id}`}
                                  target="_blank"
                                >
                                  {sharedCount >= 1
                                    ? `View shared profiles`
                                    : ""}
                                </Link>
                              )}
                            </Col>
                            {data?.status === 1 && (
                              <Col className="d-flex justify-content-between flex-column" xs={1} md={1} lg={2} align="right">
                                {(role === "admin" ||
                                  role === "Manager" ||
                                  role === "Executive") && (
                                  <Dropdown
                                    style={{
                                      backgroundColor: "none",
                                    }}
                                  >
                                    <Dropdown.Toggle
                                      // id="dropdown-basic"
                                      style={{
                                        fontSize: "20px",
                                        backgroundColor: "none",
                                        border: "none",
                                      }}
                                    >
                                      <BiDotsVerticalRounded
                                        style={{
                                          color: "black",
                                          marginLeft: "-5px",
                                          marginTop: "-2px",
                                        }}
                                      />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        href=""
                                        onClick={sendNotification}
                                      >
                                        Send Notification
                                      </Dropdown.Item>

                                      <Dropdown.Item onClick={ShareJob}>
                                        Share Chef Profiles
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                )}
                                <div className="text-end border border-3 rounded-pill d-flex justify-content-center align-items-center" style={{marginRight:'1rem'}}>
                      <p className="text-right my-1" style={{ fontSize: "1.5dvh", color: 'grey', fontWeight: 400 }}>
                        {data?.viewsCount>0&&<IoMdEye style={{ color: 'grey', fontSize: '20px' }} />}
                        &nbsp;
                        {renderViews(data?.viewsCount)}
                      </p>
                    </div>
                              </Col>
                            )}
                            <Col
                              className="d-sm-block d-md-none d-lg-none"
                              sm={8}
                              align="left"
                            >
                              <Link
                              
                                to={`/employerdetails/${data?.employerId?._id}`}
                                style={{
                                  color: "#a020f0",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#a020f0",
                                  }}
                                >
                                  {data?.employerId?.fullName}
                                </h5>
                              </Link>

                              <p>
                                Email:
                                {data?.employerId?.email}
                                <br />
                                Mobile Number:
                                {data?.employerId?.mobile}
                              </p>
                            </Col>
                          </Row>
                        )}
                        <hr />

                        {isLoading ? (
                          <Row>{DataSkeleton()}</Row>
                        ) : (
                          <Row className="card pb-3">
                            <Row>
                              {data?.status === 1 && (
                                <div align="right">
                                  {(role === "admin" || role === "Manager") && (
                                    <Link
                                      to={`/editjobdetails/${id}`}
                                      style={{
                                        color: "#ff5c09",
                                        fontWeight: "600",
                                        borderBottom: "1px solid #ff5c09",
                                      }}
                                    >
                                      Edit
                                    </Link>
                                  )}
                                </div>
                              )}
                              <h5
                                style={{ fontWeight: "500", color: "#ff5c09" }}
                              >
                                Job Details
                              </h5>
                              <Col xs={12} md={12} lg={12}>
                                <p className="text-heading">Description</p>
                                {data.description && (
                                  <p className="text-details">
                                    {data.description}
                                  </p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={12}>
                                <p className="text-heading">Cuisine</p>
                                <Row
                                  style={{
                                    marginTop: "-15px",
                                    marginLeft: "0px",
                                  }}
                                >
                                  {data?.cuisines?.map(
                                    (chefCusineVal, index) => (
                                      <Col
                                        xs={5}
                                        sm={5}
                                        md={3}
                                        lg={2}
                                        xl={2}
                                        className="me-2"
                                        style={{
                                          backgroundColor: "#ffffff",
                                          borderRadius: "10px",
                                          color: "black",
                                          padding: "4px 8px",
                                          margin: "8px 0px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          textAlign: "center",
                                          fontSize: "16px",
                                          marginRight: "4px",
                                          border: "solid 1px #ff5c09",
                                          width: "auto",
                                        }}
                                      >
                                        {chefCusineVal}
                                      </Col>
                                    )
                                  )}
                                </Row>
                              </Col>

                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Salary</p>
                                {data.salary && (
                                  <p className="text-details">
                                    Rs. {data.salary}
                                  </p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Experience</p>
                                {data.experience && (
                                  <p className="text-details">
                                    {data.experience} Years
                                  </p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Openings</p>
                                {data?.openings !== 0 && (
                                  <p className="text-details">
                                    {data?.openings}
                                  </p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Urgency</p>
                                {data?.urgency && (
                                  <p className="text-details">
                                    {data?.urgency}
                                  </p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Qualification</p>
                                {data.qualification && (
                                  <p className="text-details">
                                    {data.qualification}
                                  </p>
                                )}
                              </Col>

                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Dishes</p>
                                {data.dishes && (
                                  <p className="text-details">{data.dishes}</p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Food</p>
                                {data.food === 1 && (
                                  <p className="text-details">Yes</p>
                                )}
                                {data.food === 0 && (
                                  <p className="text-details">No</p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Accommodation</p>
                                {data.accommodation === 1 && (
                                  <p className="text-details">Yes</p>
                                )}
                                {data.accommodation === 0 && (
                                  <p className="text-details">No</p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">
                                  Receive applications on WhatsApp{" "}
                                </p>
                                {data?.whatsappUpdate === 1 && (
                                  <p className="text-details">Yes</p>
                                )}
                                {data?.whatsappUpdate === 0 && (
                                  <p className="text-details">No</p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Visibility</p>
                                {data?.visibility === 1 && (
                                  <p className="text-details">
                                    Visible to all India
                                  </p>
                                )}
                                {data?.visibility === 0 && (
                                  <p className="text-details">
                                    Visible to same location
                                  </p>
                                )}
                              </Col>
                            </Row>
                            {/* <Row className="mt-3">
                              <h5
                                style={{ fontWeight: "500", color: "#ff5c09" }}
                              >
                                Employer Details
                              </h5>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Name</p>
                                {data?.employerId?.fullName && (
                                  <p className="text-details">
                                    {data.employerId.fullName}
                                  </p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Mobile Number</p>
                                {data?.employerId?.mobile && (
                                  <p className="text-details">
                                    {data.employerId.mobile}
                                  </p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Email</p>
                                {data?.employerId?.email && (
                                  <p className="text-details">
                                    {data.employerId.email}
                                  </p>
                                )}
                              </Col>

                              {/* <Col xs={12} md={12} lg={6}>
                              <p className="text-heading">Property Type</p>
                              {data?.employerId?.propertyType === 1 && (
                                <p className="text-details">Hotel</p>
                              )}
                              {data?.employerId?.propertyType === 2 && (
                                <p className="text-details">Restaurant</p>
                              )}
                              {data?.employerId?.propertyType === 3 && (
                                <p className="text-details">Cafe</p>
                              )}
                              {data?.employerId?.propertyType === 4 && (
                                <p className="text-details">Cloud Kitchen</p>
                              )}
                              {data?.employerId?.propertyType === 5 && (
                                <p className="text-details">Other</p>
                              )}
                            </Col> 
                            </Row> */}
                          </Row>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobsDetails;
